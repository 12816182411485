/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "us-west-2",
    "aws_appsync_graphqlEndpoint": "https://dukt3kbatrattjrat7ouak4fgy.appsync-api.us-west-2.amazonaws.com/graphql",
    "aws_appsync_region": "us-west-2",
    "aws_appsync_authenticationType": "AMAZON_COGNITO_USER_POOLS",
    "aws_cloud_logic_custom": [
        {
            "name": "quicksight",
            "endpoint": "https://buwj8e0i34.execute-api.us-west-2.amazonaws.com/dev",
            "region": "us-west-2"
        }
    ],
    "aws_cognito_identity_pool_id": "us-west-2:d11109ee-abfd-467a-aa47-731056962717",
    "aws_cognito_region": "us-west-2",
    "aws_user_pools_id": "us-west-2_6aICGjlkI",
    "aws_user_pools_web_client_id": "ndhfrsi8858btf6drtb4iu0f5",
    "oauth": {},
    "aws_user_files_s3_bucket_region": "us-west-2"
};


export default awsmobile;
